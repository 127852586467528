var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":_vm.store,"resource":"/customer/orders","reloadable":"","enterable":""},on:{"click-clear-filter":_vm.clearFilter,"click-reload":_vm.fetch},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.id,"noTranslate":"","bold":""}})],1)]}},{key:"first_item",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.first_item)?_c('SCardProductItems',{attrs:{"item":item.first_item.product}}):_c('TMessageNotFound')],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('SMessageOrderStatus',{attrs:{"id":item.status_id}})],1)]}},{key:"type",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-nowrap"},[_c('TMessage',{attrs:{"content":item.type.name,"noTranslate":"","bold":"","color":"muted"}})],1)]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageDateTime',{attrs:{"content":item.created_at,"small":""}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"customer_id-filter",fn:function(){return [_c('SSelectCustomer',{attrs:{"value":_vm.filter.customer_id,"noCustomLabel":"","prependAll":""},on:{"update:value":function($event){return _vm.$set(_vm.filter, "customer_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"first_item-filter",fn:function(){return [_c('TInputText',{attrs:{"value":_vm.filter['items.product_id'],"placeholder":"Jancode"},on:{"update:value":[function($event){return _vm.$set(_vm.filter, 'items.product_id', $event)},_vm.filterChange]}})]},proxy:true},{key:"status-filter",fn:function(){return [_c('SSelectOrderStatus',{attrs:{"store":"order.statuses","value":_vm.filter.status_id},on:{"update:value":function($event){return _vm.$set(_vm.filter, "status_id", $event)},"change":_vm.filterChange}})]},proxy:true},{key:"created_at-filter",fn:function(){return [_c('TInputDateTimeRange',{attrs:{"value":_vm.dateRange},on:{"update:value":[function($event){_vm.dateRange=$event},_vm.setUpdatedAtFilter]}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }